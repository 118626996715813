'use client';

import { Toaster } from '@askable/ui/core/sonner';
import { GoogleTagManager } from '@next/third-parties/google';
import { Figtree } from 'next/font/google';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import { useEffect, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Providers } from './providers';

import '@askable/ui/styles/globals.css';

const figtree = Figtree({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '600', '700', '900'],
});

const RootLayout = ({ children }: { children: ReactNode }) => {
  // HACK: prevent dark mode poking through during SSR when going to unmod routes
  // TODO: check with design if we really do want to lock the light/dark mode down here, or if we should
  // allow the UI to render in the users preference (like we do in clients)
  const pathname = usePathname();
  const [, i18n] = useTranslation();
  // Sometimes when language is set ar which is supposed to set dir to rtl, it doesn't get reflected dom...
  // Workaround is setting state to re-render
  const [isRTL, setIsRTL] = useState(false);
  const theme = pathname.startsWith('/unmod/') ? 'light' : 'dark';

  useEffect(() => {
    setIsRTL(i18n.language === 'ar');
  }, [i18n.language]);

  return (
    <html lang="en" className={`${theme} h-full`}>
      <head>
        <link rel="icon" href="/favicon.png" sizes="any" />
      </head>
      <body className={` h-full ${figtree.className}`} dir={isRTL ? 'rtl' : ''}>
        <Providers>{children}</Providers>
        <Toaster />
      </body>
      <Script
        id="intercom-script"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(){var w=window;;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + "${process.env.NEXT_PUBLIC_INTERCOM_APP_ID}";var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
        }}
      />
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID!} />
    </html>
  );
};

export default RootLayout;
