/* eslint-disable max-lines */
import { cacheExchange } from '@urql/exchange-graphcache';
import { match } from 'ts-pattern';

import { GetNotes } from '@/app/(rooms)/rooms/[videoCode]/components/Sidebar/data/Notes.query';
import { roomData } from '@/app/(rooms)/rooms/[videoCode]/roomData';
import {
  CollectionsListDocument,
  CurrentSharesDocument,
  ShareStatus,
  TeamInsightsDocument,
  InsightViewsDocument,
  CollectionViewsDocument,
  ShareRequestStatus,
  ShareRequesterType,
} from '@/generated/graphql';

import type { SubscribeNotes } from '@/app/(rooms)/rooms/[videoCode]/components/Sidebar/data/Notes.subscription';
import type { CreateNote } from '@/app/[videoCode]/playback/data/CreateNote.mutation';
import type { DeleteNote } from '@/app/[videoCode]/playback/data/DeleteNote.mutation';
import type {
  CurrentSharesQuery,
  CurrentSharesQueryVariables,
  MutationShareByEmailsArgs,
  RevokeShareAccessMutation,
  ShareByDomainMutationVariables,
  TeamInsightsQuery,
  TeamInsightsQueryVariables,
  UpdateInsightMutationVariables,
  CollectionsListQuery,
  CollectionsListQueryVariables,
  CollectionQuery,
  InsightQuery,
  View,
  UpdateShareRequestMutation,
} from '@/generated/graphql';
import type { ResultOf, VariablesOf } from 'gql.tada';

export const urqlCacheExchange = cacheExchange({
  updates: {
    Mutation: {
      createAskableVideoRoomNote: (
        result: ResultOf<typeof CreateNote>,
        args: VariablesOf<typeof CreateNote>,
        cache,
      ) => {
        if (!roomData._room_id) {
          throw new Error('Room ID is not set');
        }

        cache.updateQuery<ResultOf<typeof GetNotes>, VariablesOf<typeof GetNotes>>(
          { query: GetNotes, variables: { code: args.input.code, _room_id: roomData._room_id } },
          data => {
            data?.roomNotes?.push(result.createAskableVideoRoomNote);
            return data;
          },
        );
      },
      deleteAskableVideoRoomNote: (
        result: ResultOf<typeof DeleteNote>,
        args: VariablesOf<typeof DeleteNote>,
        cache,
      ) => {
        if (!roomData._room_id) {
          throw new Error('Room ID is not set');
        }

        cache.updateQuery<ResultOf<typeof GetNotes>, VariablesOf<typeof GetNotes>>(
          { query: GetNotes, variables: { code: args.input.code, _room_id: roomData._room_id } },
          data => {
            const updated = data?.roomNotes?.filter(note => note?._id !== args.input.id);
            return {
              ...data,
              roomNotes: updated || [],
            };
          },
        );
      },
      updateShareRequest(result: UpdateShareRequestMutation, args, cache) {
        const invalidateShareRequest = () => {
          cache.invalidate({ __typename: 'ShareRequest', _id: (args as any).input._id });
        };

        if (result.updateShareRequest?.status === ShareRequestStatus.Rejected) {
          invalidateShareRequest();
          return;
        }

        if (result.updateShareRequest?.status === ShareRequestStatus.Approved) {
          invalidateShareRequest();
          cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
            {
              query: CurrentSharesDocument,
              variables: {
                first: 30,
                filter: {
                  status: {
                    in: [ShareStatus.Invited, ShareStatus.Opened],
                  },
                  type: {
                    eq: result.updateShareRequest.type,
                  },
                  type_id: {
                    eq: result.updateShareRequest.type_id,
                  },
                },
              },
            },
            data => {
              if (data?.shares.nodes && result.updateShareRequest?.share) {
                data.shares.nodes.push(result.updateShareRequest.share);
              }

              return data;
            },
          );
        }
      },
      shareByEmails(result, args, cache) {
        cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
          {
            query: CurrentSharesDocument,
            variables: {
              first: 30,
              filter: {
                status: {
                  in: [ShareStatus.Invited, ShareStatus.Opened],
                },
                type: {
                  eq: (args as MutationShareByEmailsArgs)?.input?.type,
                },
                type_id: {
                  eq: (args as MutationShareByEmailsArgs)?.input?.type_id,
                },
              },
            },
          },
          data => {
            data!.shares!.nodes.push(...(result.shareByEmails as any));
            return data;
          },
        );
      },
      createShare(result, args, cache) {
        return cache
          .inspectFields('Query')
          .filter(field => field.fieldName === 'shares')
          .forEach(() => {
            cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
              {
                query: CurrentSharesDocument,
                variables: {
                  first: 30,
                  filter: {
                    status: {
                      in: [ShareStatus.Invited, ShareStatus.Opened],
                    },
                    type: {
                      eq: (args as ShareByDomainMutationVariables).input?.type,
                    },
                    type_id: {
                      eq: (args as ShareByDomainMutationVariables).input?.type_id,
                    },
                    requester_type: {
                      ne: ShareRequesterType.Team,
                    },
                  },
                },
              },
              data => {
                data!.shares!.nodes!.push(result.createShare as any);
                return data;
              },
            );
          });
      },
      revokeShare(result, args, cache, _info) {
        return cache
          .inspectFields('Query')
          .filter(field => field.fieldName === 'shares')
          .forEach(() => {
            const typedResult = result as RevokeShareAccessMutation;
            cache.updateQuery<CurrentSharesQuery, CurrentSharesQueryVariables>(
              {
                query: CurrentSharesDocument,
                variables: {
                  first: 30,
                  filter: {
                    status: {
                      in: [ShareStatus.Invited, ShareStatus.Opened],
                    },
                    type: {
                      eq: typedResult?.revokeShare?.type,
                    },
                    type_id: {
                      eq: typedResult?.revokeShare?.type_id,
                    },
                  },
                },
              },
              data => {
                data!.shares!.nodes! = data?.shares?.nodes.filter(a => a?._id !== args.id)!;
                return data;
              },
            );
          });
      },
      updateInsight(_result, args, cache, _info) {
        const typedArgs = args as UpdateInsightMutationVariables;
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'insights')
          .forEach(field => {
            cache.updateQuery<TeamInsightsQuery>(
              {
                query: TeamInsightsDocument,
                variables: field.arguments as TeamInsightsQueryVariables,
              },
              data => {
                const i = data?.insights.nodes?.findIndex(a => a?._id === typedArgs.input.id);

                if (i && data?.insights?.nodes?.[i]) {
                  data.insights.nodes[i] = {
                    ...data?.insights?.nodes?.[i]!,
                    title: typedArgs.input.title,
                  };
                }

                return data;
              },
            );
          });
      },
      createInsight(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'insights')
          .forEach(field => {
            cache.updateQuery<TeamInsightsQuery>(
              {
                query: TeamInsightsDocument,
                variables: field.arguments as TeamInsightsQueryVariables,
              },
              data => {
                data?.insights?.nodes?.push(result.createInsight as any);

                data!.insights.totalCount += 1;

                return data;
              },
            );
          });
      },
      createCollection(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'collections')
          .forEach(field => {
            cache.updateQuery<CollectionsListQuery>(
              {
                query: CollectionsListDocument,
                variables: field.arguments as CollectionsListQueryVariables,
              },
              data => {
                data?.collections?.nodes?.push(result.createCollection as any);

                return data;
              },
            );
          });
      },
      deleteCollection(result, args, cache, _info) {
        cache
          .inspectFields('Query')
          .filter((field: any) => field.fieldName === 'collections')
          .forEach(field => {
            cache.updateQuery<CollectionsListQuery>(
              {
                query: CollectionsListDocument,
                variables: field.arguments as CollectionsListQueryVariables,
              },
              data => {
                data!.collections!.nodes = data?.collections.nodes?.filter(r => r?._id?.toString() !== args._id);

                return data;
              },
            );
          });
      },
      updateInsightViews(result, args, cache, _info) {
        cache.updateQuery<InsightQuery>({ query: InsightViewsDocument, variables: args }, data => {
          if (result.updateInsightViews) {
            data!.insight!.views = result.updateInsightViews as View[];
          }
          return data;
        });
      },
      updateCollectionViews(result, args, cache, _info) {
        cache.updateQuery<CollectionQuery>({ query: CollectionViewsDocument, variables: args }, data => {
          if (result.updateCollectionViews) {
            data!.collection!.views = result.updateCollectionViews as View[];
          }
          return data;
        });
      },
    },
    Subscription: {
      videoRoomNote(result: ResultOf<typeof SubscribeNotes>, args: VariablesOf<typeof SubscribeNotes>, cache) {
        if (!roomData._room_id) {
          throw new Error('Room ID is not set');
        }

        cache.updateQuery<ResultOf<typeof GetNotes>, VariablesOf<typeof GetNotes>>(
          { query: GetNotes, variables: { code: args.filter.code, _room_id: roomData._room_id } },
          data => {
            const updateNote = result.videoRoomNote;

            // For some reasons, subscription triggered by the same user cause issue when updating the cache here...
            // Basically, the second cache update override the first one.
            // So we skip the update in that case.
            if (roomData._user_id === result.videoRoomNote?.note?._user_id) {
              return data;
            }

            const updatedData = match(updateNote)
              .with({ type: 'delete' }, note => {
                const updatedNotes = data?.roomNotes?.filter(_note => _note?._id !== note?.note?._id);
                if (updatedNotes) {
                  return {
                    ...data,
                    roomNotes: updatedNotes,
                  };
                }
                return data;
              })
              .with({ type: 'create' }, note => {
                const updatedNotes = [...(data?.roomNotes ?? []), note.note];
                return {
                  ...data,
                  roomNotes: updatedNotes,
                };
              })
              .with({ type: 'update' }, note => {
                const index = data?.roomNotes?.findIndex(note => note?._id === note?._id);
                if (typeof index === 'number' && index !== -1 && data?.roomNotes?.[index] && note?.note) {
                  data.roomNotes[index] = note.note;
                }
                return data;
              })
              .otherwise(() => {
                return data;
              });

            return updatedData;
          },
        );
      },
    },
  },
});
