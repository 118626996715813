import { resources, i18n } from '@askable/i18n';
import { initI18nZodMap, isLanguage } from '@askableorg/i18n';
import { z } from 'zod';

import { Locale } from '@/generated/graphql';

const initialLang =
  typeof window !== 'undefined' ? (window.localStorage.getItem('lang') ?? navigator.language ?? 'en-AU') : 'en-AU';

export const initialLocale = (isLanguage(initialLang) ? initialLang.replace('-', '_') : Locale.EnUs) as Locale;

export const EnglishVariantLocales = [Locale.En, Locale.EnUs, Locale.EnGb, Locale.EnAu];

i18n.init({
  lng: initialLang,
  ns: ['sessions', 'common', 'zod', 'components'],
  resources,
});

z.setErrorMap(initI18nZodMap(i18n));

export { z };
export default i18n;
