'use client';

import { datadogRum } from '@datadog/browser-rum';
import { FeatureFlagsProvider, UserFeatureFlagProvider } from 'feature-flags';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'urql';

import { SetUpKinde } from '@/components/SetUpKinde/SetUpKinde';
import { IntercomProvider, useIntercom } from '@/context/IntercomContext/IntercomContext';
import i18n from '@/src/i18n';

import { AccessTokenSaver__DEPRECATED } from '../AccessTokenSaver_DEPRECATED';
import { DatadogInit } from '../DatadogInit';
import { QUERY_PARAM } from '../constants/constants';
import { ViewerContextProvider, useViewerContext } from '../context/ViewerContext/ViewerContext';
import { client } from '../network/client';
import { isDesktop } from '../utils/device-utils';
import { environment } from '../utils/environment-utils';

import PageLoading from './(dashboard)/loading';
import { MobileError } from './components/MobileError';

import type { ReactElement } from 'react';

const featureFlagImpressions = {
  impressionListener: {
    logImpression: ({ impression }: { impression: { feature: string; treatment: string } }) => {
      datadogRum.addFeatureFlagEvaluation(impression.feature, impression.treatment);
    },
  },
};

const WHITE_LISTED_MOBILE_ROUTES = ['insights', 'shares', 'whoops', 'ai-mod', 'ai-moderated', 'unmod', 'prototype'];

const MyApp = ({ children }: { children: ReactElement }) => {
  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const { details, status, type, updateViewerStatus, intercomSettings, fetchingDetails } = useViewerContext();
  const Intercom = useIntercom();

  useEffect(() => {
    if (window.localStorage.getItem('lang')) {
      i18n.changeLanguage(window.localStorage.getItem('lang')!);
    }
  }, []);

  useEffect(() => {
    if (!environment.isProductionBuild) {
      return;
    }

    if (!intercomSettings) {
      return;
    }

    Intercom.boot(intercomSettings);
  }, [intercomSettings, Intercom]);

  const shouldShowMobileError = useMemo(() => {
    const routeVal = pathName?.split('/') ?? [];
    const whiteListedRoutes = routeVal.some(v => WHITE_LISTED_MOBILE_ROUTES.includes(v));
    return !whiteListedRoutes && !isDesktop;
  }, [pathName]);

  useEffect(() => {
    // This useEffect might be triggered multiple times (e.g. when isReady is updated or router.replace updates route)
    // When user is on /whoops and tries to replace with /whoops, it will emit error
    // Takes user to whoops page if device type is not supported
    // We cannot call multiple router.replace in one process. (e.g. takes user to whoops page and then lobby page without token)
    // so make sure return the statement and two replaces are not called.
    if (shouldShowMobileError) {
      return;
    }

    updateViewerStatus();

    if (searchParams?.has(QUERY_PARAM.AUTH_TOKEN) || searchParams?.has(QUERY_PARAM.TEAM_ID)) {
      router?.replace?.(window.location.pathname, undefined);
    }
  }, [router, pathName]);

  useEffect(() => {
    if (!intercomSettings || !environment.isProductionBuild) {
      return;
    }
    Intercom.update(intercomSettings);
  }, [intercomSettings]);

  if (fetchingDetails) {
    return <PageLoading />;
  }

  return (
    <div className="h-full">
      {status !== 'unknown' && (
        <UserFeatureFlagProvider
          app="sessions"
          userId={details?._id}
          userAttributes={{ type: type[0], team: details?.ConnectedTeam?._id ?? '' }}
          anonymousAttributes={{ demo: environment.isDemo }}
        >
          {({ isReady }) => {
            if (!isReady) {
              return <PageLoading />;
            }
            return (
              <>
                {shouldShowMobileError ? <MobileError /> : null}
                {children}
              </>
            );
          }}
        </UserFeatureFlagProvider>
      )}
    </div>
  );
};

const WrappedProviders = props => {
  return (
    <SetUpKinde>
      <I18nextProvider i18n={i18n}>
        <IntercomProvider>
          <Provider value={client}>
            <ViewerContextProvider>
              <MyApp {...props} />
              <DatadogInit />
            </ViewerContextProvider>
          </Provider>
        </IntercomProvider>
      </I18nextProvider>
    </SetUpKinde>
  );
};

export const Providers = props => {
  return (
    <AccessTokenSaver__DEPRECATED>
      <FeatureFlagsProvider
        apiKey={environment.featureFlagsApiKey}
        config={featureFlagImpressions}
        app="sessions"
        attributes={{ demo: environment.isDemo }}
      >
        {({ isReady }) => {
          if (!isReady) {
            return <PageLoading />;
          }

          return <WrappedProviders {...props} />;
        }}
      </FeatureFlagsProvider>
    </AccessTokenSaver__DEPRECATED>
  );
};
