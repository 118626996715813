import { graphql } from 'src/graphql';

// Get the room notes on initial load
export const GetNotes = graphql(`
  query RoomNotes($code: String!, $_room_id: ID!) {
    roomNotes(code: $code, _room_id: $_room_id) {
      _id
      created
      updated
      time
      note
      displayName
      _user_id
    }
  }
`);
